import { useContext, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import * as Sentry from "@sentry/react";
import SentryErrorBoundary from "./helpers/SentryErrorBoundary";

//providers
import { ThemeContext } from "./providers/CustomThemeProvider";
import RequireAuth from "./auth/RequireAuth";

import Loading from "./helpers/Loading";

//pages
import Home from "./pages/Home";
import Forbidden from "./pages/User/Forbidden";
const Retail = lazy(() => import("./pages/Retail/Retail"));
const Finish = lazy(() => import("./pages/Finish/Finish"));
const Material = lazy(() => import("./pages/Material/Material"));
const Settings = lazy(() => import("./pages/User/Settings"));
const Documentation = lazy(() => import("./pages/User/Documentation"));
const ErrorLog = lazy(() => import("./pages/User/ErrorLog"));

const FullfillViewOrderExternal = lazy(() => import("./pages/Retail/componenets/FulfillViewOrderExternal"));

// Create Custom Sentry Route component
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

interface RouteProps {
	setPageButtons: (v: any) => void;
	setPageTitle: (v: string) => void;
	setClientSet: (v: boolean) => void;
}

const Router = (props: RouteProps) => {
	const { setPageButtons, setPageTitle, setClientSet } = props;

	const { setTheme } = useContext(ThemeContext);

	let userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

	/*
	if (!userInfo?.authenticatedUsersData?.id) {
		return (
			<SentryErrorBoundary showDialog={true}>
				<Suspense fallback={<Loading />}>
					<SentryRoutes>
						<Route
							path="/settings"
							element={
								<RequireAuth>
									<Settings {...props} setClientSet={setClientSet} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
								</RequireAuth>
							}
						/>
						<Route
							element={
								<RequireAuth>
									<Home {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
								</RequireAuth>
							}
						/>
					</SentryRoutes>
				</Suspense>
			</SentryErrorBoundary>
		);
	}
	*/

	return (
		<SentryErrorBoundary showDialog={true}>
			<Suspense fallback={<Loading />}>
				<SentryRoutes>
					<Route path="/Forbidden" element={<Forbidden {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />} />
					<Route path="/errorLog" element={<ErrorLog {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />} />
					<Route path="/documentation" element={<Documentation {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />} />
					<Route
						path="/"
						element={
							<RequireAuth>
								<Home {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
							</RequireAuth>
						}
					/>
					<Route
						path="/retail/*"
						element={
							<RequireAuth>
								<Retail {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
							</RequireAuth>
						}
					/>
					<Route
						path="/finish/*"
						element={
							<RequireAuth>
								<Finish {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
							</RequireAuth>
						}
					/>
					<Route
						path="/material/*"
						element={
							<RequireAuth>
								<Material {...props} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
							</RequireAuth>
						}
					/>
					<Route
						path="/settings"
						element={
							<RequireAuth>
								<Settings {...props} setClientSet={setClientSet} setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />
							</RequireAuth>
						}
					/>
					<Route path="/vieworder/:tenantId/:orderId/:accessToken" element={<FullfillViewOrderExternal />} />
				</SentryRoutes>
			</Suspense>
		</SentryErrorBoundary>
	);
};

export default Router;
