import { useContext } from "react";

import { useLocation } from "react-router-dom";

//material-ui
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import { Alert, AlertTitle, AppBar, Toolbar, Typography, IconButton, Badge, Box, Paper } from "@mui/material";
import { Icon } from "../components/Icons";
import { IconName } from "@fortawesome/fontawesome-svg-core";

//providers
import { AuthContext } from "../auth/AuthProvider";

//local
import Config from "../config";
import PageButtons from "./PageButtons";
import ModalDialog from "../components/ModalDialog";
import { TranslationContext } from "../providers/TranslationProvider";

const useStyles = makeStyles((theme: Theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
		"@media (max-width: 400px)": {
			marginRight: 0,
		},
	},
	toolbarButtons: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row-reverse",
	},
	profile: {
		fontSize: ".8em",
		position: "absolute",
		backgroundColor: theme.palette.info.main,
		color: theme.palette.info.contrastText,
	},
	profileWarn: {
		fontSize: "1.5em",
		backgroundColor: theme.palette.error.main,
	},
	internet: {
		padding: "0",
		fontSize: "14px",
		lineHeight: "14px",
		position: "absolute",
		right: 1,
		bottom: 0,
	},
	internetSlow: {
		color: theme.palette.warning.main,
	},
	internetBad: {
		color: theme.palette.error.main,
	},
	internetChecking: {
		color: theme.palette.info.main,
	},
	branch: {
		fontSize: ".6em",
		fontweight: "bold",
		position: "absolute",
		textAlign: "center",
		width: "100%",
		backgroundColor: theme.palette.warning.main,
		color: theme.palette.warning.contrastText,
	},

	pageTitle: {},
}));

interface TopBarProps {
	pageTitle: string;
	setDrawerOpen: (v: boolean) => void;
	updateAvailable: boolean;
	pageButtons: any;
	internetStatus: number;
	updateRequired: boolean;
	setUpdateRequired: (v: boolean) => void;
	applyUpdate: () => void;
	children: JSX.Element;
}

const TopBar = (props: TopBarProps) => {
	const { pageTitle, setDrawerOpen, updateAvailable, pageButtons, internetStatus, updateRequired, setUpdateRequired, applyUpdate, children } = props;
	const location = useLocation();
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { getTranslation } = useContext(TranslationContext);
	const internetIconNames: IconName[] = ["cloud-question", "cloud-xmark", "cloud-check", "cloud-check"];
	const internetIconColors = ["orange", "red", "inherit", "yellow"];

	let profile = sessionStorage.getItem("profile");

	if (location.pathname.indexOf("/vieworder") === 0) {
		return null;
	}
	return (
		<>
			{!authContext.sessionChecked || !authContext.currentUser ? (
				<AppBar id="appHeader" position="sticky">
					{Config.environment !== "production" && Config.branch !== "" ? (
						<Box className={classes.branch}>
							&nbsp;{Config.branch} - {Config.buildVersion}&nbsp;
						</Box>
					) : null}
					<Toolbar>
						<Typography variant="h6" noWrap>
							{pageTitle}
						</Typography>
					</Toolbar>
				</AppBar>
			) : (
				<AppBar id="appHeader" position="sticky" enableColorOnDark={true}>
					{Config.environment !== "production" && Config.branch !== "" ? (
						<Box className={classes.branch}>
							&nbsp;{Config.branch} - {Config.buildVersion}&nbsp;
						</Box>
					) : null}
					{!profile ? (
						<Box className={classes.profile}>
							&nbsp;
							{getTranslation("warning-no-profile-selected")}
							&nbsp;
						</Box>
					) : (
						<>
							{profile?.toLowerCase() !== "default" ? (
								<Box className={classes.profile}>
									&nbsp;
									{getTranslation("settings-profile")}: {profile}&nbsp;
								</Box>
							) : null}
						</>
					)}
					{profile?.toLowerCase() === "default" && Config.environment !== "production" ? (
						<Alert severity="error" variant="filled">
							{getTranslation("warning-using-production-api")} {getTranslation("settings-profile")}: {profile}&nbsp;
						</Alert>
					) : null}
					{internetStatus >= 0 && internetStatus < 4 ? (
						<Box className={classes.internet}>
							<Icon icon={internetIconNames[internetStatus]} color={internetIconColors[internetStatus]} />
						</Box>
					) : null}
					<Toolbar sx={{ paddingRight: { xs: "8px", sm: "16px" } }}>
						<IconButton color="inherit" aria-label="open drawer" onClick={() => setDrawerOpen(true)} edge="start" className={classes.menuButton} size="large">
							<Badge color="error" variant="dot" invisible={!updateAvailable} overlap="rectangular">
								<Icon icon="bars" />
							</Badge>
						</IconButton>

						<Typography variant="h6" noWrap className={classes.pageTitle}>
							{pageTitle}
						</Typography>
						<div className={classes.toolbarButtons}>
							<PageButtons pageButtons={pageButtons} />
						</div>
					</Toolbar>
					{children}
				</AppBar>
			)}
			{authContext.sessionChecked && authContext.error ? (
				<Alert severity="error">
					<AlertTitle>{getTranslation("error-authentication-error")}</AlertTitle> <h3> {getTranslation("info-contact-manager-for-assistance")}</h3>
					<p>
						<b>{getTranslation("error-error-details")}:</b> {authContext.error}
					</p>
				</Alert>
			) : null}
			<ModalDialog
				title={getTranslation("warning-app-update-required")}
				actions={[
					{
						children: getTranslation("action-remind-me-later"),
						props: {
							color: "cancel",
							onClick: () => {
								setUpdateRequired(false);
							},
						},
					},
					{
						children: getTranslation("action-update-app"),
						props: {
							color: "primary",
							onClick: () => {
								applyUpdate();
							},
						},
					},
				]}
				open={updateRequired}
				close={() => {
					return false;
				}}
			>
				<Paper style={{ padding: "1em" }}>
					<Typography variant="body1">{getTranslation("warning-post-outstanding-items-and-update")}</Typography>
				</Paper>
			</ModalDialog>
		</>
	);
};

export default TopBar;
